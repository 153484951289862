<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车场管理</el-breadcrumb-item>
      <el-breadcrumb-item>通道列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="margin-bottom: 20px; display: flex; align-items: center; flex-wrap: wrap"
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="pkDoorListcx"
          >查询</el-button
        >
        <el-button size="small" @click="pkDoorListcz()">重置</el-button>
      </div>
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="doorName"
            label="通道名称"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="regionName"
            label="停车区域"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inoutFlag"
            label="进出标志"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.inoutFlag == 1 ? "出" : "进" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="masterFlag"
            label="主副通道"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.masterFlag == 0 ? "主通道" : "副通道" }}</span>
            </template>
          </el-table-column>
          <el-table-column header-align="left" align="left" prop="qrCode" label="付款码">
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.qrCode"
                :preview-src-list="qrimgList"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="noplateInQrcode"
            label="无牌车入场码"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.noplateInQrcode"
                :preview-src-list="noplateInqrimgList"
              >
              </el-image>
            </template>
          </el-table-column>
        </el-table>
      </transition>
    </el-card>
  </div>
</template>

<script>
import { pkDoorList, findPkInfoSelect } from "@/api/Parkinglot.js";
export default {
  data() {
    return {
      isShowData: false,
      tableData: [],
      query: {
        // 查询条件
        pk_id: "", // 停车场id
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      noplateInqrimgList: [],
      qrimgList: [],
    };
  },
  created() {
    this.findPkInfoSelect();
  },
  methods: {
    pkDoorList() {
      // 进出记录查询列表
      pkDoorList({ pk_id: this.query.pk_id }).then((res) => {
        this.tableData = res.data.data;
        this.noplateInqrimgList = res.data.data.map((item) => item.noplateInQrcode);
        this.qrimgList = res.data.data.map((item) => item.qrCode);

        if (this.tableData) {
          this.isShowData = true;
        }
      });
    },
    findQuery(code) {
      // 翻译停车场
      const item = this.PkInfoSelect.find((item) => item.id == code);
      return item ? item.pkName : "";
    },
    pkDoorListcx() {
      // 查询
      this.query.current = 1;
      this.pkDoorList();
    },
    pkDoorListcz() {
      this.query.pk_id = this.PkInfoSelect[0].id;
      this.pkDoorList();
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === "200") {
          this.PkInfoSelect = res.data.data;
          this.query.pk_id = this.PkInfoSelect[0].id;
          this.pkDoorList();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-image-viewer__close {
  color: #333;
}
::v-deep .el-image-viewer__mask {
  position: fixed;
  opacity: 0;
  background-color: #333;
}
::v-deep .el-image-viewer__canvas {
  height: 80%;
  padding-top: 100px;
  box-sizing: border-box;
}
::v-deep .el-image-viewer__wrapper {
  top: 50px;
  left: 20%;
  right: 20%;
  bottom: 50px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 20px 1px #333;
}
</style>
